import { createLocalizedPathnamesNavigation, Pathnames } from 'next-intl/navigation';
import { ExtendedPathnames } from '@/lib/navigationFunctions';

import { solutionPaths } from '@/lib/constants/solutions';
import { marketingTransformationPaths } from '@/lib/constants/marketing-transformation';
import { articlePaths } from '@/lib/constants/articles';
import { vacancyPaths } from '@/lib/constants/vacancies';
import { conceptPaths } from '@/lib/constants/concepts';
import { capitalize } from '@/lib/utils';
import { dynamicWebsiteLocationsPathnames, dynamicWebsiteTypesLocationsPathnames, dynamicWebsiteTypesPathnames } from '@/lib/constants/creation';
import { dynamicWebappLocationsPathnames, dynamicWebappTypesLocationsPathnames, dynamicWebappTypesPathnames } from '@/lib/constants/creation';
import { dynamicPlatformLocationsPathnames, dynamicPlatformTypesLocationsPathnames, dynamicPlatformTypesPathnames } from '@/lib/constants/creation';
import { dynamicPortalLocationsPathnames, dynamicPortalTypesLocationsPathnames, dynamicPortalTypesPathnames } from '@/lib/constants/creation';
import { dynamicPluginLocationsPathnames, dynamicPluginTypesLocationsPathnames, dynamicPluginTypesPathnames } from '@/lib/constants/creation';

export const locales = ['en', 'nl'] as const;
export type Locales = "en" | "nl";
export const localePrefix = 'as-needed';
// export const localePrefix = 'always';
export const defaultLocale = 'nl';

const dynamicSolutionsPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of solutionPaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/solutions",
        breadcrumbParentKey: "/solutions",
        includeInMenu: true,
    }
    dynamicSolutionsPathnames[`${page.link.en}`] = newPage
}

const dynamicMarketingTransformationsPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of marketingTransformationPaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/marketing-transformation",
        breadcrumbParentKey: "/marketing-transformation",
        includeInMenu: true,
    }
    dynamicMarketingTransformationsPathnames[`${page.link.en}`] = newPage
}

const dynamicArticlePathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of articlePaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/articles",
        breadcrumbParentKey: "/articles",
    }
    dynamicArticlePathnames[`${page.link.en}`] = newPage
}

export const dynamicConceptPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of conceptPaths) {
    const newPage = {
        ...page.link,
        name: capitalize(page.name),
        parentKey: "/concept",
        breadcrumbParentKey: "/concept",
    }
    dynamicConceptPathnames[`${page.link.en}`] = newPage
}

const dynamicVacancyPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of vacancyPaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/vacancies",
        breadcrumbParentKey: "/about#vacancies",
    }
    dynamicVacancyPathnames[`${page.link.en}`] = newPage
}






export const loopExtendedPathnames = (): ExtendedPathnames<typeof locales> => {
    return {
        "/": {
            en: "/",
            nl: "/",
            name: "home",
            includeInMenu: true,
        },
        "/algemene-voorwaarden-webbeukers": {
            nl: "/algemene-voorwaarden-webbeukers",
            en: "/terms-and-conditions-webbeukers",
            name: "terms_and_conditions_webbeukers",
            showInMenu: false,
        },
        "/documents/Algemene-voorwaarden-Webbeukers.pdf": {
            nl: "/documents/Algemene-voorwaarden-Webbeukers.pdf",
            en: "/documents/Algemene-voorwaarden-Webbeukers.pdf",
            name: "terms_and_conditions_webbeukers",
            showInMenu: false,
        },
        "/cookie-policy": {
            nl: "/cookie-statement",
            en: "/cookie-policy",
            name: "cookie_policy",
            showInMenu: false,
        },
        "/privacy-policy": {
            nl: "/privacy-beleid",
            en: "/privacy-policy",
            name: "privacy_policy",
            showInMenu: false
        },
        '/websites': {
            en: '/websites',
            nl: '/websites',
            name: 'websites',
            showInMenu: true,
            includeInMenu: true,
            breadcrumbParentKey: "/",
        },
        // '/articles': {
        //     en: '/articles',
        //     nl: '/artikelen',
        //     name: 'articles',
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/cases': {
        //     en: '/cases',
        //     nl: '/projecten',
        //     name: 'website_cases',
        //     parentKey: "/websites",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/about#approach': {
        //     en: '/about#approach',
        //     nl: '/over#approach',
        //     name: 'our_approach',
        //     parentKey: "/websites",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/solutions': {
        //     en: '/solutions',
        //     nl: '/oplossingen',
        //     name: 'solutions',
        //     showInMenu: true,
        //     parentKey: "/websites",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // // '/websites#price': {
        // //     en: '/websites#price',
        // //     nl: '/websites#price',
        // //     name: 'price_indications',
        // //     parentKey: "/websites",
        // //     includeInMenu: true,
        // //     breadcrumbParentKey: "/",
        // // },
        // // 'price-indications': {
        // //     en: '/price-indications',
        // //     nl: '/prijsindicaties',
        // //     name: 'price_indications',
        // //     parentKey: "/websites",
        // //     includeInMenu: true,
        // //     breadcrumbParentKey: "/",
        // // },
        // '/concept': {
        //     en: '/concept',
        //     nl: '/vaktaal',
        //     name: 'concept',
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },

        // '/websites#reviews': {
        //     en: '/websites#reviews',
        //     nl: '/websites#reviews',
        //     name: 'customer_reviews',
        //     parentKey: "/websites",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/marketing-transformation': {
        //     en: '/marketing-transformation',
        //     nl: '/marketing-transformatie',
        //     name: 'marketing',
        //     showInMenu: true,
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // "/about": {
        //     en: "/about",
        //     nl: "/over",
        //     name: "about_us",
        //     showInMenu: true,
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/about#team': {
        //     en: '/about#team',
        //     nl: '/over#team',
        //     name: 'the_team',
        //     parentKey: "/about",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/about#vacancies': {
        //     en: '/about#vacancies',
        //     nl: '/over#vacancies',
        //     name: 'vacancies',
        //     parentKey: "/about",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/what-does-a-website-cost': {
        //     en: '/what-does-a-website-cost',
        //     nl: '/wat-kost-een-website',
        //     name: 'what_does_a_website_cost',
        //     parentKey: "/websites",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // '/contact': {
        //     en: '/contact',
        //     nl: '/contact',
        //     name: 'contact',
        //     showInMenu: true,
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // // '/test': {
        // //     en: '/test',
        // //     nl: '/test',
        // //     name: 'test',
        // // },

        // '/development-variants': {
        //     en: '/development-variants',
        //     nl: '/ontwikkel-varianten',
        //     name: 'development_variants',
        //     parentKey: "/websites",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/",
        // },
        // '/website-creation': {
        //     en: '/website-creation',
        //     nl: '/website-laten-maken',
        //     name: 'have_a_website_created',
        //     parentKey: "/websites",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // ...dynamicSolutionsPathnames,
        // '/web-app-creation': {
        //     en: '/web-app-creation',
        //     nl: '/web-app-laten-maken',
        //     name: 'have_a_webapp_created',
        //     parentKey: "/solutions",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        // // '/platform-creation': {
        // //     en: '/platform-creation',
        // //     nl: '/platform-laten-maken',
        // //     name: 'have_a_platform_created',
        // //     parentKey: "/",
        // //     breadcrumbParentKey: "/",
        // // },
        // // '/portal-creation': {
        // //     en: '/portal-creation',
        // //     nl: '/portaal-laten-maken',
        // //     name: 'have_a_portal_created',
        // //     parentKey: "/",
        // //     breadcrumbParentKey: "/",
        // // },
        // // '/plugin-creation': {
        // //     en: '/plugin-creation',
        // //     nl: '/plugin-laten-maken',
        // //     name: 'have_a_plugin_created',
        // //     parentKey: "/",
        // //     breadcrumbParentKey: "/",
        // // },

        // ...dynamicWebsiteTypesPathnames,
        // ...dynamicWebsiteLocationsPathnames,
        // ...dynamicWebsiteTypesLocationsPathnames,

        // ...dynamicWebappLocationsPathnames,
        // ...dynamicWebappTypesLocationsPathnames,
        // ...dynamicWebappTypesPathnames,

        // // ...dynamicPlatformLocationsPathnames,
        // // ...dynamicPlatformTypesLocationsPathnames,
        // // ...dynamicPlatformTypesPathnames,

        // // ...dynamicPortalLocationsPathnames,
        // // ...dynamicPortalTypesLocationsPathnames,
        // // ...dynamicPortalTypesPathnames,

        // // ...dynamicPluginLocationsPathnames,
        // // ...dynamicPluginTypesLocationsPathnames,
        // // ...dynamicPluginTypesPathnames,


        // ...dynamicMarketingTransformationsPathnames,
        // ...dynamicArticlePathnames,
        // ...dynamicConceptPathnames,
        // ...dynamicVacancyPathnames,
    }
};
export const extendedPathnames = loopExtendedPathnames();

export const extendedPathnamesArray = Object.keys(extendedPathnames).map(key => {
    return {
        key,
        ...extendedPathnames[key]
    }
});


export const menuExtendedPathnames = Object.keys(extendedPathnames).reduce((acc, key) => {
    if (extendedPathnames[key].includeInMenu) {
        acc[key] = extendedPathnames[key];
    }
    return acc;
}, {} as ExtendedPathnames<typeof locales>);

const mapBackToPathnames = (pathnames: ExtendedPathnames<typeof locales>) => {    
    return Object.keys(pathnames).reduce((acc, key) => {
        acc[key] = {
            en: pathnames[key].en,
            nl: pathnames[key].nl
        }
        return acc;
    }, {} as Pathnames<typeof locales>);
}

export const pathnames = mapBackToPathnames(extendedPathnames);

export const {Link, redirect, usePathname, useRouter, getPathname} = createLocalizedPathnamesNavigation({locales, localePrefix, pathnames});